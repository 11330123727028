import * as React from "react"
import { Helmet } from "react-helmet"
import Layout from '../../components/Layout/Layout'

// markup
const IndexPage = () =>
(
    <Layout>
        <Helmet title="Covid 19 Notice" defer={false} />
        <div className="rounded overflow-hidden shadow-lg py-6 px-6">
            <h1 className="text-5xl font-bold mt-4 mb-4">Covid 19 Notice</h1>
            <div className="space-y-3">
                <p>
                    Our client's mental and physical health is our priority.  We are taking extra precautions with keeping our offices and waiting area disinfected frequently.
                </p>
                <p>
                    We ask that sick client's or family members please stay home and reschedule your appointment.
                </p>
                <p>
                    We are following CDC guidelines and minimizing the individuals in our waiting area.  We ask that only clients and parents and/or guardian attend appointments.
                </p>
                <p>
                    Please talk to your therapist if you have any questions.
                </p>
            </div>
        </div>
    </Layout>
)

export default IndexPage
